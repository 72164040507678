import axios from 'axios'
import qs from 'qs'
// const url="http://192.168.1.100:8081"
const url=""
axios.defaults.baseURL = url
axios.defaults.withCredentials = true

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // 判断是否存在token,如果存在将每个页面header添加token
  if (window.sessionStorage.getItem("token")) {
    config.headers.common['token'] = sessionStorage.getItem("token");
  }
  // config.headers.common['Accept'] = 'application/json';
  // config.headers.common['Content-Type'] = 'application/json'


  return config
})
export default axios;
