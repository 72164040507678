
import Vue from 'vue'
import VueRouter from 'vue-router'


//1.安装插件
Vue.use(VueRouter);

//2.创建路由对象
const routes = [
  {
    path: '/',
    name: '前台主页 ',
    meta:{
      title:"前台主页",
      keepAlive: true
    },
    component: () =>
    import( /* webpackChunkName: "page" */ '@/views/home/index.vue')
  },
  {
    path: '/login',
    name: '登录',
    meta:{
      title:"登录",
      keepAlive: true
    },
    component: () =>
    import( /* webpackChunkName: "page" */ '@/views/login/index.vue')
  },
  {
    path: '/backstage',
    name: '后台主页',
    meta:{
      title:"后台主页",
      keepAlive: true
    },
    component: () =>
        import( /* webpackChunkName: "page" */ '@/views/backstage/index.vue')
  },
]
const router = new VueRouter({
  routes
})

//3.导出router
export default router
