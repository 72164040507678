import Vue from 'vue'
import App from './App'
import router from './router'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from '@/router/axios.js'


Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})
// 添加请求拦截器
// axios.interceptors.request.use(function (config) {
//   // 在发送请求之前做些什么
//   // 判断是否存在token,如果存在将每个页面header添加token
//   if (sessionStorage.getItem("token")) {
//     config.headers.common['token'] = sessionStorage.getItem("token");
//   }
//   return config
// })
Vue.use(Element)//使用element-ui

Vue.prototype.$axios = axios

/* eslint-disable no-new */
new Vue({
  el: '#app',
  //不要忘了挂载router
  router,
  render: h => h(App)
})

